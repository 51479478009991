<template>
  <div class="page-contact">
    <top-cover :option="topCoverOption" />

    <main>
      <section class="section a">
        <ul class="content">
          <li class="item" v-for="(A,a) in option" :key="$randomKey(a)">
            <h3>{{A.name}}</h3>
            <ul>
              <li v-for="(B,b) in A.content" :key="$randomKey(b)">{{B}}</li>
            </ul>
            <span>{{A.label}}</span>
          </li>
        </ul>
      </section>
    </main>

    <partner />
    <page-footer class="footer" />
  </div>
</template>

<script>
import topCover from '@/components/TopCover'
import pageFooter from '@/components/Footer'
import partner from '@/components/Partner'

export default {
  components: {
    topCover,
    pageFooter,
    partner,
  },
  data() {
    return {
      topCoverOption: {
        title: '公司地址：福建省厦门市软件园二期·望海路14号楼之二',
        cover: require('@/assets/map@2x.png'),
        withCover: true
      },

      option: [
        {
          name: '内容/平台合作',
          label: '欢迎 游戏商、硬件厂商合作',
          content: [
            '电话：18759946965',
            '微信号：zamervr'
          ]
        },
        {
          name: 'VR体验馆合作',
          label: 'VR体验馆合作、开店咨询',
          content: [
            '电话：18759946965',
            '微信号：zamervr'
          ]
        },
        {
          name: '造梦售后服务',
          label: '技术问题、售后问题请咨询',
          content: [
            '电话：17743536864',
            '微信号：ZamerVR-Online'
          ]
        },
        {
          name: '投递邮箱',
          label: '简历投递、意见反馈',
          content: ['hr@zmvr.com']
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  padding-top: 24px;
}
.section.a {
  width: 1080px;
  margin: 0 auto;

  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 27px;
    padding: 0;

    .item {
      height: 220px;
      list-style: none;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0 24px;
      box-sizing: border-box;

      h3 {
        font-size: 24px;
        color: #323436;
        line-height: 1;
        font-weight: lighter;
        margin: 0;
      }
      span {
        font-size: 16px;
        color: rgba(#323436, 0.5);
      }
      ul {
        padding: 0;
        li {
          list-style: none;
          font-size: 18px;
          color: #323436;
          line-height: 1;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>